import {
    MOVEABLE_PROPS_MAP,
    MOVEABLE_PROPS,
    MOVEABLE_METHODS,
    MOVEABLE_EVENTS,
} from "react-moveable";

export const PROPERTIES: string[] = MOVEABLE_PROPS;
export const METHODS: string[] = MOVEABLE_METHODS;
export const EVENTS: string[] = MOVEABLE_EVENTS;
export const PROPS_MAP: Record<string, any> = MOVEABLE_PROPS_MAP;
